import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { handleExperimentsResponse } from '../../common/store/experiments/experiments-actions';
import { handleTagCloudWidgetResponse } from '../aggregated-tag-cloud/actions';

export const fetchTagCloudWidgetInitialData =
  ({ useYoshiExperiments, useICU } = {}) =>
  async (dispatch, getState, { aggregatorRequest }) => {
    const langQuery = getQueryLocale(getState());
    const language = langQuery ? `&language=${langQuery}` : '';
    const icu = useICU ? '&icu=true' : '';

    const { experiments, tagCloud } = await aggregatorRequest(
      `/v1/tag-cloud-widget/render-model?onlyWithPublishedPosts=true${language}${icu}`,
      {
        throwOnInvalidJson: true,
      },
    );

    await Promise.all([
      useYoshiExperiments ? undefined : dispatch(handleExperimentsResponse(experiments)),
      dispatch(handleTagCloudWidgetResponse(tagCloud)),
    ]);
  };

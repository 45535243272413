import { createAction } from '@wix/communities-blog-client-common';
import { SANTA_MEMBERS_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { isFrameless } from '../../../common/controller/helpers';

export const SET_VIEWED_USER = 'viewedUser/SET';

const setViewedUser = createAction(SET_VIEWED_USER);

export const fetchViewedUser =
  () =>
  async (dispatch, getState, { wixCodeApi }) => {
    if (!isFrameless({ state: getState(), wixCodeApi })) {
      return;
    }
    const api = await wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID);
    const viewedUser = await api.getViewedUser();
    dispatch(setViewedUser(viewedUser));
  };

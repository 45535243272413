import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { setLocale, setQueryLocaleAction } from '../../common/store/locale/locale-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { getInstance } from '../../common/controller/helpers';
import { setCommunitiesContext } from '../../common/store/communities-context/communities-context-actions';
import { setCustomRoutes } from '../../common/store/custom-routes/custom-routes-actions';
import { fetchExperiments } from '../../common/store/experiments/experiments-actions';
import { fetchMyPostsPageInitialData, fetchMyPostsPageDataPromisified } from '../store/initial-data/fetch-initial-data';
import { setActiveTabPromisified } from '../store/active-tab/active-tab-actions';
import {
  initializePromisifiedActions as initializeCommonPromisifiedActions,
  initUserDependentStoreBaseData,
} from '../../common/controller/init-actions';
import { fetchViewedUser } from '../store/viewed-user/viewed-user-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { fetchTopology } from '../../common/store/topology/topology-actions';

export { initializeActions, refreshDataOnLogin } from '../../common/controller/init-actions';

export function initializePromisifiedActions({ store }) {
  return initializeCommonPromisifiedActions(
    { store },
    {
      fetchMyPostsPageDataPromisified,
      setActiveTabPromisified,
    },
  );
}

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  appParams,
  context = {},
}) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initialiseInstanceValues(getInstance(wixCodeApi)()));

  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));

  await Promise.all([
    store.dispatch(fetchExperiments(context.experimentsPromise)),
    store.dispatch(fetchViewedUser()),
    initUserDependentStoreBaseData({ store, wixCodeApi, allowPreviewInstance: false }),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(setBasicParams({ viewMode, language, biPageNumber: platformAPIs.bi.pageNumber })),
    store.dispatch(setAppConfig({ bundleName })),
    store.dispatch(setCustomRoutes()),
  ]);

  const instanceId = getInstanceId(store.getState());
  await store.dispatch(fetchTopology(instanceId));

  await store.dispatch(fetchMyPostsPageInitialData());
}

import { createRequestWithBaseUrl, appendOriginInBackend, getInstance } from '../../controller/helpers';
import { parseInstance } from '../instance-values/parse-instance';
import { EXPERIMENTS_SCOPE } from './experiments-types';

export default function fetchExperimentsRequest({
  wixCodeApi,
  baseUrls: { apiExperimentsBaseUrlClient },
  bundleName,
  scope = EXPERIMENTS_SCOPE,
  flowAPI,
}) {
  const { metaSiteId } = parseInstance(getInstance(wixCodeApi)());
  const request = createRequestWithBaseUrl({ wixCodeApi, bundleName, flowAPI });
  return request(appendOriginInBackend({ wixCodeApi, baseUrl: apiExperimentsBaseUrlClient }))(
    `/v1/laboratory/platform/conductAllInScope?scope=${scope}&requestContext.overrideCriteria.entityId=${metaSiteId}`,
  );
}

require('abortcontroller-polyfill/dist/abortcontroller-polyfill-only');

import { InitAppForPageFn } from '@wix/yoshi-flow-editor';
import { getUseYoshiExperiments } from './external/common/controller/helpers';
import fetchExperimentsRequest from './external/common/store/experiments/experiments-request';

const bundleName = 'viewer-script';
const appData: { experimentsPromise?: Promise<any> } = { experimentsPromise: undefined };

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  { essentials },
  flowAPI,
) => {
  const appParams = initParams;

  if (process.env.NODE_ENV !== 'production' || wixCodeApi.location.query.debug === 'true') {
    console.log('initAppForPage', { appParams, platformApis, wixCodeApi, essentials });
  }

  if (getUseYoshiExperiments(appParams)) {
    appData.experimentsPromise = Promise.resolve({ values: essentials.experiments.all() });
  } else {
    appData.experimentsPromise = fetchExperimentsRequest({
      wixCodeApi,
      baseUrls: appParams.baseUrls,
      bundleName,
      flowAPI,
    } as any);
  }

  // will be passed as an `appData` for each controller
  return appData;
};

import { nthArg } from 'lodash';

import { createAction } from '@wix/communities-blog-client-common';
import { normalizePosts } from '../../common/services/post-utils';

export const FETCH_RELATED_POSTS_REQUEST = 'relatedPosts/FETCH_REQUEST';
export const FETCH_RELATED_POSTS_SUCCESS = 'relatedPosts/FETCH_SUCCESS';
export const FETCH_RELATED_POSTS_FAILURE = 'relatedPosts/FETCH_FAILURE';

export const fetchRelatedPostsRequest = createAction(FETCH_RELATED_POSTS_REQUEST);
export const fetchRelatedPostsSuccess = createAction(FETCH_RELATED_POSTS_SUCCESS, nthArg(0), nthArg(1));
export const fetchRelatedPostsFailure = createAction(FETCH_RELATED_POSTS_FAILURE);

export default function fetchRelatedPosts(post) {
  return (dispatch, getState, { request }) => {
    const url = '/v2/posts/publications';
    const params = { postId: post._id };
    dispatch(fetchRelatedPostsRequest(params));

    const promise = request.post(url, { postsIds: post.relatedPostIds });

    return promise
      .then((response) =>
        dispatch(
          fetchRelatedPostsSuccess(normalizePosts({ state: getState(), posts: response.posts, origin: url }), params),
        ),
      )
      .catch(() => dispatch(fetchRelatedPostsFailure()))
      .then(() => promise);
  };
}
